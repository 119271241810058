<template>
  <v-card>

    <v-dialog v-model="showAlertModal" max-width="450">
      <v-card>
        <v-card-text>
          <div id="icon">
            <v-icon @click="showAlertModal = false" color="primary" id="icon-child" size="45">
              {{ icons.mdiCloseCircleOutline }}
            </v-icon>
          </div>

          <v-container>
            <span class="text-h6">The form is ideally filled by Counsellor Cum Technician.
              <br />
              This form is <strong> Not filled</strong>
              yet.</span>
            <br /><br />
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>


    <v-stepper v-model="e1">
      <v-stepper-header>
        <v-stepper-step :complete="e1 > 1" step="1"> step 1 </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step :complete="e1 > 2" step="2"> step 2 </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step :complete="e1 > 3" step="3"> step 3 </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step step="4"> step 4 </v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>

        <v-stepper-content step="1">
          <v-card-title class="justify-center">
            <span class="text-h5"><strong> DONOR CASE INFORMATION</strong></span>
          </v-card-title>
          <v-row class="ml-3 mr-5 pt-3 mb-3">
            <v-col cols="12" md="4">
              <v-text-field v-model="preValueList.donor_name" label="Donor Name" readonly></v-text-field>
            </v-col>
          </v-row>
          <v-card-title class="justify-start">
            <span class="font-weight-semibold">Details of Consent</span>
          </v-card-title>
          <v-card-subtitle class="mb-8">
            <span>( please encircle the contact who gave final consent )</span>
          </v-card-subtitle>

          <v-radio-group class="mt-0" v-model="preValueList.contact_radio_value" :readonly="readOnlyField1 === true"
            :error="errr === false">

            <v-card-subtitle class="mb-n5 mt-n5">
              <div class="diii">
                <v-radio name="active" value="Contact 1"></v-radio>
                <span class="font-weight-semibold text--primary me-1">Consent Contact</span>
              </div>
            </v-card-subtitle>
            <v-row class="ml-3 mr-5 pt-3 mb-3">
              <v-col cols="12" md="6">
                <v-text-field v-model="preValueList.given_name" label="Given Name*" readonly></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field v-model="preValueList.family_name" label="Family Name*" readonly></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field v-model="preValueList.relation_with_donor" label="Relationship*" readonly></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-autocomplete v-model="preValueList.contact1_sex" :items="Sex" label="Sex*"
                  :readonly="readOnlyField11 === false" :error="errr40 === false"></v-autocomplete>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field v-model="preValueList.contact1_approx_age" label="Approx Age*" counter="2"
                  @keypress="onlyAge" @paste.prevent :readonly="readOnlyField1 === true"
                  :error="errr1 === false"></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field v-model="preValueList.contact1_phone" label="Phone no.*" counter="10" @keypress="onlyNumber"
                  @paste.prevent :readonly="readOnlyField1 === true" :error="errr2 === false"></v-text-field>
              </v-col>
            </v-row>


            <v-card-subtitle class="mb-n5 mt-5">
              <div class="diii">
                <v-radio name="active" value="Contact 2"></v-radio>
                <span class="font-weight-semibold text--primary me-1">Witness</span>
              </div>
            </v-card-subtitle>
            <v-row class="ml-3 mr-5 pt-3 mb-3">
              <v-col cols="12" md="6">
                <v-text-field v-model="preValueList.contact2_given_name" label="Given Name"
                  :readonly="readOnlyField1 === true" :error="errr3 === false"></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field v-model="preValueList.contact2_family_name" label="Family Name"
                  :readonly="readOnlyField1 === true" :error="errr4 === false"></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field v-model="preValueList.contact2_relationship"
                  @click="relationsphip(preValueList.contact2_relationship)" label="Relationship"
                  :readonly="readOnlyField1 === true" :error="errr5 === false"></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-autocomplete v-model="preValueList.contact2_sex" :items="Sex" :readonly="readOnlyField1 === true"
                  :error="errr6 === false" label="Sex"></v-autocomplete>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field v-model="preValueList.contact2_approx_age" label="Approx Age" counter="2"
                  @keypress="onlyAge" @paste.prevent :readonly="readOnlyField1 === true"
                  :error="errr7 === false"></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field v-model="preValueList.contact2_phone" label="Phone no." counter="10" @keypress="onlyNumber"
                  @paste.prevent :readonly="readOnlyField1 === true" :error="errr8 === false"></v-text-field>
              </v-col>
            </v-row>

            <!-- <v-card-subtitle class="mb-n5 mt-5">
            <div class="diii">
              <v-radio name="active" value="Contact 3"></v-radio>
            <span class="font-weight-semibold text--primary me-1">Contact 3</span>
            </div>
          </v-card-subtitle> -->
            <!-- <v-row class="ml-3 mr-5 pt-3" >
            <v-col cols="12" md="6">
              <v-text-field
                v-model="preValueList.contact3_given_name"
                label="Given Name"
                :readonly="readOnlyField1 === true"
                :error="errr9 === false"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="preValueList.contact3_family_name"
                label="Family Name"
                :readonly="readOnlyField1 === true"
                :error="errr10 === false"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field
                v-model="preValueList.contact3_relationship"
                label="Relationship"
                :readonly="readOnlyField1 === true"
                :error="errr11 === false"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <v-autocomplete 
                v-model="preValueList.contact3_sex" 
                :items="Sex" 
                :readonly="readOnlyField1 === true"
                :error="errr12 === false"
                label="Sex"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field
                v-model="preValueList.contact3_approx_age"
                label="Approx Age"
                counter="2"
                :readonly="readOnlyField1 === true"
                @keypress="onlyAge"
                @paste.prevent
                :error="errr13 === false"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field
                v-model="preValueList.contact3_phone"
                :readonly="readOnlyField1 === true"
                label="Phone no."
                counter="10"
                @keypress="onlyNumber"
                @paste.prevent
                :error="errr14 === false"
              ></v-text-field>
            </v-col>
          </v-row> -->
          </v-radio-group>

          <v-row class="mx-3 mb-1">
            <v-col cols="12" md="12" class="text-right">
              <v-btn color="primary" v-if="saveBtn" class="mr-3" @click="formBFirst()"> Save </v-btn>
              <v-btn color="primary" text @click="e1 = 2"> Next </v-btn>
            </v-col>
          </v-row>
        </v-stepper-content>


        <v-stepper-content step="2">
          <v-card-title class="justify-start">
            <span class="font-weight-semibold">APPROACH INFORMATION</span>
          </v-card-title>
          <v-row class="mx-3 pt-3">
            <v-col cols="12" md="2">
              <p class="body-2 mt-1">Approached:*</p>
            </v-col>
            <v-col cols="12" md="3">
              <v-radio-group v-model="preValueList.approch" row class="mt-0" :readonly="readOnlyField1 === true"
                :error="errr15 === false">
                <v-radio name="active" label="Yes" value="Yes"></v-radio>
                <v-radio name="active" label="No" value="No"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row class="mx-3">
            <v-col cols="12" md="6">
              <v-text-field v-model="preValueList.introduce_by" label="Introduced By(Given Name, Family Name)"
                :readonly="readOnlyField1 === true"></v-text-field>
            </v-col>
          </v-row>
          <v-row class="mx-3">
            <v-col cols="12" md="4">
              <v-text-field v-model="preValueList.formal_requested_by" label="Formal Request By" readonly></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-autocomplete v-model="preValueList.formal_requested_type" :items="List" label="Formal Request Type"
                readonly></v-autocomplete>
            </v-col>
          </v-row>
          <v-row class="mx-3">
            <v-col cols="12" md="6">
              <v-text-field v-model="preValueList.relationship_title_designation" label="Relationship/Title/Designation*"
                :readonly="readOnlyField1 === true" :error="errr16 === false"></v-text-field>
            </v-col>
          </v-row>
          <v-row class="mx-3">
            <v-col cols="12" md="2">
              <p class="body-2 mt-1">Family Decision:*</p>
            </v-col>
            <v-col cols="12" md="4">
              <v-radio-group class="mt-0" v-model="preValueList.family_decision" row :readonly="readOnlyField1 === true"
                :error="errr17 === false">
                <v-radio name="active" label="Yes" value="Yes"></v-radio>
                <v-radio name="active" label="No" value="No"></v-radio>
                <v-radio name="active" label="N/A" value="N/A"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <!-- <v-row class="mx-3">
            <v-col cols="12">
              <v-textarea
                v-model="preValueList.comments"
                label="Comments"
                rows="2"
                row-height="15"
                :readonly="readOnlyField1 === true"
              ></v-textarea>
            </v-col>
            <v-col cols="12">
              <v-textarea
                v-model="preValueList.reason"
                label="Brief Description on how motivation was carried and reasons for getting or not getting the consent"
                rows="4"
                row-height="15"
                :readonly="readOnlyField1 === true"
              ></v-textarea>
            </v-col>
          </v-row> -->
          <v-row class="mx-3 mb-2">
            <v-col cols="12" md="12" class="text-right">
              <v-btn color="primary" class="float-left" text @click="refresh()"> Previous </v-btn>
              <v-btn color="primary" class="mr-3" v-if="saveBtn" @click="formBSecond()"> Save </v-btn>
              <v-btn color="primary" text @click="e1 = 3"> Next </v-btn>
            </v-col>
          </v-row>
        </v-stepper-content>

        <v-stepper-content step="3">
          <v-card-title>
            <span class="font-weight-semibold">OUTCOME</span>
          </v-card-title>
          <v-radio-group class="mt-0" v-model="preValueList.outcome" :readonly="readOnlyField1 === true"
            :error="errr18 === false">
            <v-row class="mx-3 pt-3">
              <v-col cols="12" sm="4">
                <v-radio name="active" value="Approached - Not consented" label="Approached - Not consented"
                  @click="restradio()" :readonly="readOnlyField === true"></v-radio>
                <!-- <v-checkbox 
                label="Approached - Not consented" 
                v-model="preValueList.not_consented" 
                value="true"
                hide-details
              ></v-checkbox> -->
              </v-col>
              <v-col cols="12" sm="4">
                <v-autocomplete :items="LocationsList" v-if="preValueList.outcome == 'Approached - Not consented'"
                  label="Outcome" v-model="preValueList.not_consented_reason" :readonly="readOnlyField === true"
                  :error="errr19 === false"></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="4">
                <v-textarea label="other reason why approached but not consented"
                  v-model="preValueList.not_consented_description"
                  v-if="preValueList.not_consented_reason == 'other reason why approached but not consented' && preValueList.outcome == 'Approached - Not consented'"
                  row-height="15" rows="1" :readonly="readOnlyField === true"></v-textarea>
              </v-col>
            </v-row>
            <v-row class="mx-3">
              <v-col cols="12" sm="4">
                <v-radio name="active" value="Consented - Not recovered" label="Consented - Not recovered"
                  @click="restradio1()" :readonly="readOnlyField === true"></v-radio>
                <!-- <v-checkbox 
                label="Consented - Not recovered" 
                v-model="preValueList.not_recovered" 
                value="true"
                hide-details
              ></v-checkbox> -->
              </v-col>
              <v-col cols="12" sm="4">
                <v-autocomplete :items="bodyLocationsList" v-if="preValueList.outcome == 'Consented - Not recovered'"
                  label="Outcome" v-model="preValueList.not_recovered_reason" :readonly="readOnlyField === true"
                  :error="errr20 === false"></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="4">
                <v-textarea label="Other reason why cansented but not recovered"
                  v-model="preValueList.not_recovered_description"
                  v-if="preValueList.not_recovered_reason == 'Other reason why cansented but not recovered' && preValueList.outcome == 'Consented - Not recovered'"
                  row-height="15" rows="1" :readonly="readOnlyField === true"></v-textarea>
              </v-col>
            </v-row>
            <v-row class="mx-3">
              <v-col cols="12" sm="4">
                <v-radio name="active" value="Consented - Donor" label="Consented - Donor" @click="restradio2()"
                  :readonly="readOnlyField === true"></v-radio>
                <!-- <v-checkbox 
                label="Consented - Donor" 
                v-model="preValueList.consented_donor" 
                value="true"
                hide-details
              ></v-checkbox> -->
              </v-col>
              <v-col cols="12" sm="4">
                <v-autocomplete :items="SuitableList" v-if="preValueList.outcome == 'Consented - Donor'" label="Outcome"
                  v-model="preValueList.consented_donor_reason" :readonly="readOnlyField === true"
                  :error="errr21 === false"></v-autocomplete>
              </v-col>
            </v-row>
          </v-radio-group>
          <v-row class="mx-3 mb-2">
            <v-col cols="12" md="12" class="text-right">
              <v-btn color="primary" class="float-left" text @click="refresh1()"> Previous </v-btn>
              <v-btn color="primary" class="mr-3" v-if="saveBtn" @click="formBThird()"> Save </v-btn>
              <v-btn color="primary" text @click="e1 = 4"> Next </v-btn>
            </v-col>
          </v-row>
        </v-stepper-content>

        <v-stepper-content step="4">
          <v-card-title class="mb-n2 mt-8">
            <span class="font-weight-semibold">CONSENT</span>
          </v-card-title>
          <v-card-subtitle class="mb-3">
            <span>If Donor has given consent please complete consent form</span>
          </v-card-subtitle>
          <v-card-title class="mb-n2 mt-4">
            <span class="font-weight-semibold">CONSULT</span>
          </v-card-title>

          <v-row class="mx-3">
            <v-col cols="12" md="5">
              <v-text-field v-model="preValueList.person_consulted" label="Person Consulted"
                :readonly="readOnlyField1 === true"></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <v-datetime-picker v-model="consultDateTime" date-format="dd-MM-yyyy" time-format="HH:mm"
                label="Date and Time" :readonly="readOnlyField1 === true"></v-datetime-picker>
            </v-col>
          </v-row>
          <v-row class="mx-3">
            <v-col cols="12" md="8">
              <v-textarea v-model="preValueList.topic" label="Topic" rows="2" :readonly="readOnlyField1 === true"
                row-height="15"></v-textarea>
            </v-col>
          </v-row>
          <v-row class="mx-3">
            <v-col cols="12" md="8">
              <v-textarea v-model="preValueList.discussion" label="Discussion" rows="3"
                :readonly="readOnlyField1 === true" row-height="15"></v-textarea>
            </v-col>
          </v-row>
          <v-row class="mx-3">
            <p class="pl-3 body-2 font-italic">If the donor family has consented please move on to FORM C</p>
          </v-row>
          <v-row class="mx-3 mb-2">
            <v-col cols="12" md="12" class="text-right">
              <v-btn color="primary" class="float-left" text @click="refresh2()"> Previous </v-btn>
              <v-btn color="primary" v-if="saveBtn" @click="same()"> Save </v-btn>
            </v-col>
          </v-row>
        </v-stepper-content>

      </v-stepper-items>
      <v-snackbar v-model="snackbar" :timeout="timeout">
        {{ snackbarText }}

        <template v-slot:action="{ attrs }">
          <v-btn color="blue" text v-bind="attrs" @click="snackbar = false"> Close </v-btn>
        </template>
      </v-snackbar>
    </v-stepper>
  </v-card>
</template>


<style>
#icon {
  width: 100%;
  text-align: right;
}

#icon-child {
  margin-right: -10px;
  margin-top: 5px;
}
</style>

<script>
import donorService from '@/service/Donor.service'
import axios from 'axios'
import moment from 'moment'
import { mdiMagnify, mdiCloseCircleOutline, mdiGithub } from '@mdi/js'
const localData = (JSON.parse(localStorage.getItem('token')) === null) ? window.location.href = '/' : JSON.parse(localStorage.getItem('token'));
const token = localData.token
const baseUrl = "https://api.ebsr.in/"
const userId = localData.user_id
import { api } from '@/config/config'


export default {
  components: {

  },
  data() {
    return {
      errr: true,
      errr1: true,
      errr2: true,
      errr3: true,
      errr4: true,
      errr5: true,
      errr6: true,
      errr7: true,
      errr8: true,
      errr9: true,
      errr10: true,
      errr11: true,
      errr12: true,
      errr13: true,
      errr14: true,
      errr15: true,
      errr16: true,
      errr17: true,
      errr18: true,
      errr19: true,
      errr20: true,
      errr21: true,
      errr40: true,
      formtype: '',
      readOnlyField: false,
      readOnlyField1: false,
      readOnlyField11: true,
      saveBtn: true,
      radio_group: '',
      snackbar: false,
      snackbarText: '',
      timeout: 2000,
      Notscreen: '',
      ScreenedNotscreen: '',
      showAlertModal: false,
      Suitable: '',
      not_approched: '',
      not_consented: '',
      not_recovered: '',
      consented_donor: '',
      listLoading: false,
      e1: '1',
      preValueList: {
        id: '',
        date_time: '',
        contact2_sex: '',
        contact1_sex: '',
        relation_with_donor: '',
        contact_radio_value: 'Contact 1'
      },
      Sex: ['Male', 'Female'],
      List: ['Eye Bank Staff', 'Hospital Staff', 'Volunteer'],
      LocationsList: [
        'Religion',
        'Aggravated or emotional family',
        'pt. previous discussion',
        'Patient wishes unknown',
        'Disagreement among family members',
        'Wants to keep the body intact after death',
        'family demands & expectations',
        'consent recanted',
        'other reason why approached but not consented'
      ],
      bodyLocationsList: [
        'police do not allow donation',
        'body shifted to another place',
        'Delay in reaching donor site',
        'Post-consent screening',
        'unable to draw blood sample',
        'Other reason why consented but not recovered'
      ],
      SuitableList: [
        'Became a donor'
      ],
    }
  },


  computed: {
    consultDateTime: {
      get() {
        return moment.utc(this.preValueList.date_time).format('DD-MM-YYYY HH:mm')
      },
      set(value) {
        this.$set(this.preValueList, 'date_time', moment(value).format('YYYY-MM-DD HH:mm'))
      },
    },
  },


  setup() {


    return {
      // Icons
      icons: {
        mdiCloseCircleOutline,
      },
    }
  },

  watch: {
    options: {
      handler() {
        this.donorDeatilView()
        this.getdonorList()
      },
      deep: true,
    },
  },

  async mounted() {
    this.donorDeatilView()
    this.getdonorList()
  },

  methods: {

    refresh() {
      this.e1 = 1
      this.donorDeatilView()
    },
    refresh1() {
      this.e1 = 2
      this.donorDeatilView()
    },
    refresh2() {
      this.e1 = 3
      this.donorDeatilView()
    },

    same() {
      this.formBFour()
      // if(this.formtype.view != '0' && this.formtype.view != null){
      //   this.$router.push({name:'Form_Cpart'});
      // }
      // console.log("this.preValueList.outcome",this.preValueList.outcome)

      if (this.preValueList.outcome == 'Consented - Donor') {
        this.$router.push({ name: 'Form_Cpart' })
      } else {
        return false
      }

    },
    restradio() {
      if (this.preValueList.outcome == 'Approached - Not consented') {
        this.preValueList.not_consented_reason = '',
          this.preValueList.not_consented_description = ''
        this.not_consented = true;
        // not_recovered: '',
        // consented_donor: '',
      }
    },
    restradio1() {
      if (this.preValueList.outcome == 'Consented - Not recovered') {
        this.preValueList.not_recovered_reason = '',
          this.preValueList.not_recovered_description = ''
        this.not_recovered = true;
      }
    },
    restradio2() {
      if (this.preValueList.outcome == 'Consented - Donor') {
        this.preValueList.consented_donor_reason = ''
        this.consented_donor = true;
      }
    },
    relationsphip(type) {
      if (type == Son) {
        this.preValueList.contact2_sex = 'Male'
      }

    },

    onlyNumber($event) {
      let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
      if (keyCode < 48 || keyCode > 57 || $event.target._value.length == 10) {
        $event.preventDefault();
      }

    },


    onlyAge($event) {
      let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
      if (keyCode < 48 || keyCode > 57 || $event.target._value.length == 2) {
        $event.preventDefault();
      }
    },


    async donorDeatilView() {
      const DonorId = this.$route.params.donor_id
      const service = new donorService()
      let response = await service.FormBData(DonorId)
      this.preValueList = response
      this.readOnlyFun()
      if (this.preValueList.relation_with_donor == 'Son') {  
        this.preValueList.contact1_sex = "Male"
        this.readOnlyField11 = false
      } else if (this.preValueList.relation_with_donor == 'Wife' || this.preValueList.relation_with_donor == 'Daughter') {
        this.preValueList.contact1_sex = 'Female'
        this.readOnlyField11 = false
      } else {
        this.preValueList.contact1_sex = this.preValueList.contact1_sex
        this.readOnlyField11 = true
      }
    },

    async readOnlyFun() {
      if (this.preValueList.outcome == "" && this.preValueList.outcome == "") {
        this.readOnlyField = false
      } else if (this.preValueList.outcome == null && this.preValueList.outcome == null) {
        this.readOnlyField = false
      }
      else {
        this.readOnlyField = true
      }
    },

    async getdonorList() {
      this.listLoading = true
      const service = new donorService()
      let response = await service.donorList()
      if (response) {
        this.permissionData = response.permissions
        var formBData = this.permissionData[2]
        this.formtype = this.permissionData[3]
        // console.log("heloo",formBData)
        if (formBData.view == "1" && formBData.edit == null || formBData.view == "1" && formBData.edit == "0") {
          this.readOnlyField1 = true
          this.saveBtn = false

          console.log('check form b permission', this.preValueList.id)
          if (!this.preValueList.id) {
            this.showAlertModal = true
          }
        }
      } else {
        this.totallist = 0
      }
      this.listLoading = false
    },

    async formBFirst() {


      if (this.preValueList.contact_radio_value == "" || this.preValueList.contact_radio_value == null) {
        this.errr = false
        return false,
          this.snackbarText = "Please Select Contact.",
          this.snackbar = true
      }
      else if (this.preValueList.contact1_sex == "" || this.preValueList.contact1_sex == null) {
        this.errr40 = false
        return false,
          this.snackbarText = "Please Fill the Contact sex.",
          this.snackbar = true
      }
      else if (this.preValueList.contact1_approx_age == "" || this.preValueList.contact1_approx_age == null) {
        this.errr1 = false
        return false,
          this.snackbarText = "Please Fill the Contact approx age.",
          this.snackbar = true
      }
      else if (this.preValueList.contact1_phone == '' || this.preValueList.contact1_phone == null) {
        this.errr2 = false
        return false,
          this.snackbarText = "Please Fill the Contact1 Phone.",
          this.snackbar = true
      }
      else if (this.preValueList.contact1_phone.length != "" && this.preValueList.contact1_phone.length < 10) {
        this.errr2 = false
        return false,
          this.snackbarText = "Please Enter 10 digit Contact1 Phone.",
          this.snackbar = true
      }
      //  if(this.a != 9){
      //   console.log("helll",this.a )
      //   return false,
      //   this.snackbarText = "please Fill the phone Number",
      //   this.snackbar = true
      // }
      // else if(this.preValueList.contact2_given_name == "" || this.preValueList.contact2_given_name == null){
      //   this.errr3 = false
      //   return false,
      //   this.snackbarText = "Please Fill the Contact 2 Given Name.",
      //   this.snackbar = true
      // }
      // else if(this.preValueList.contact2_family_name == "" || this.preValueList.contact2_family_name == null){
      //   this.errr4 = false
      //   return false,
      //   this.snackbarText = "Please Fill the Contact 2 Family Name.",
      //   this.snackbar = true
      // }else if(this.preValueList.contact2_relationship == "" || this.preValueList.contact2_relationship == null){
      //   this.errr5 = false
      //   return false,
      //   this.snackbarText = "please Fill the contact 2 relationship",
      //   this.snackbar = true
      // }else if(this.preValueList.contact2_sex == "" || this.preValueList.contact2_sex == null){
      //   this.errr6 = false
      //   return false,
      //   this.snackbarText = "please Fill the contact 2 sex",
      //   this.snackbar = true
      // }else if(this.preValueList.contact2_approx_age == "" || this.preValueList.contact2_approx_age == null){
      //   this.errr7 = false
      //   return false,
      //   this.snackbarText = "please Fill the contact 2 approx age",
      //   this.snackbar = true
      // }else if(this.preValueList.contact2_phone == "" || this.preValueList.contact2_phone == null){
      //   this.errr8 = false
      //   return false,
      //   this.snackbarText = "please Fill the contact 2 Phone",
      //   this.snackbar = true
      // }
      else if (this.preValueList.contact2_phone.length != "" && this.preValueList.contact2_phone.length < 10) {
        this.errr8 = false
        return false,
          this.snackbarText = "please Enter 10 digit contact 2 Phone",
          this.snackbar = true
      }


      // else if(this.preValueList.contact3_given_name == "" || this.preValueList.contact3_given_name == null){
      //   this.errr9 = false
      //   return false,
      //   this.snackbarText = "please Fill the contact 3 given name",
      //   this.snackbar = true
      // }else if(this.preValueList.contact3_family_name == "" || this.preValueList.contact3_family_name == null){
      //   this.errr10 = false
      //   return false,
      //   this.snackbarText = "please Fill the contact 3 family name",
      //   this.snackbar = true
      // }else if(this.preValueList.contact3_relationship == "" || this.preValueList.contact3_relationship == null){
      //   this.errr11 = false
      //   return false,
      //   this.snackbarText = "please Fill the contact 3 relationship",
      //   this.snackbar = true
      // }else if(this.preValueList.contact3_sex == "" || this.preValueList.contact3_sex == null){
      //   this.errr12 = false
      //   return false,
      //   this.snackbarText = "please Fill the contact 3 sex",
      //   this.snackbar = true
      // }else if(this.preValueList.contact3_approx_age == "" || this.preValueList.contact3_approx_age == null){
      //   this.errr13 = false
      //   return false,
      //   this.snackbarText = "please Fill the contact 3 approx age",
      //   this.snackbar = true
      // }else if(this.preValueList.contact3_phone == "" || this.preValueList.contact3_phone == null){
      //   this.errr14 = false
      //   return false,
      //   this.snackbarText = "please Fill the contact 3 phone",
      //   this.snackbar = true
      // }
      // else if(this.preValueList.contact3_phone.length != "" && this.preValueList.contact3_phone.length < 10 ){
      //   this.errr14 = false
      //   return false,
      //   this.snackbarText = "please Enter 10 digit contact 3 phone",
      //   this.snackbar = true
      // }
      else {
        this.errr = true
        this.errr1 = true
        this.errr2 = true
        this.errr3 = true
        this.errr4 = true
        this.errr5 = true
        this.errr6 = true
        this.errr7 = true
        this.errr8 = true
        this.errr9 = true
        this.errr10 = true
        this.errr11 = true
        this.errr12 = true
        this.errr13 = true
        this.errr14 = true
      }

      const data = {
        donor_id: this.$route.params.donor_id,
        userid: userId,
        contact_radio_value: this.preValueList.contact_radio_value,
        contact1_sex: this.preValueList.contact1_sex,
        contact1_approx_age: this.preValueList.contact1_approx_age,
        contact1_phone: this.preValueList.contact1_phone,
        contact2_given_name: this.preValueList.contact2_given_name,
        contact2_family_name: this.preValueList.contact2_family_name,
        contact2_relationship: this.preValueList.contact2_relationship,
        contact2_sex: this.preValueList.contact2_sex,
        contact2_approx_age: this.preValueList.contact2_approx_age,
        contact2_phone: this.preValueList.contact2_phone,
        // contact3_given_name: this.preValueList.contact3_given_name,
        // contact3_family_name: this.preValueList.contact3_family_name,
        // contact3_relationship: this.preValueList.contact3_relationship,
        // contact3_sex: this.preValueList.contact3_sex,
        // contact3_approx_age: this.preValueList.contact3_approx_age,
        // contact3_phone: this.preValueList.contact3_phone,
      }
      try {
        const response = await api.post(`donorCase/add`, data, {
          headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        })
        if (response.data.status === true) {
          this.snackbarText = response.data.msg
          this.donorDeatilView()
        } else {
          this.snackbarText = response.data.msg
        }
        this.snackbar = true
      } catch (e) {
        console.log(e)
      }
    },

    async formBSecond() {
      if (this.preValueList.approch == "" || this.preValueList.approch == null) {
        this.errr15 = false
        return false,
          this.snackbarText = "Please Fill the Approch.",
          this.snackbar = true
      }
      if (this.preValueList.relationship_title_designation == "" || this.preValueList.relationship_title_designation === null) {
        this.errr16 = false
        return false,
          this.snackbarText = "Please Fill the Relationship / Title / Designation.",
          this.snackbar = true
      }
      if (this.preValueList.family_decision == "" || this.preValueList.family_decision == null) {
        this.errr17 = false
        return false,
          this.snackbarText = "Please Fill the Family Decision.",
          this.snackbar = true
      } else {
        this.errr15 = true
        this.errr16 = true
        this.errr17 = true
      }

      const data = {
        donor_id: this.$route.params.donor_id,
        userid: userId,
        approch: this.preValueList.approch,
        introduce_by: this.preValueList.introduce_by,
        relationship_title_designation: this.preValueList.relationship_title_designation,
        family_decision: this.preValueList.family_decision,
        // comments: this.preValueList.comments,
        // reason: this.preValueList.reason,
      }
      try {
        const response = await api.post(`donorCase/add`, data, {
          headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        })
        if (response.data.status === true) {
          this.snackbarText = response.data.msg
          this.donorDeatilView()
        } else {
          this.snackbarText = response.data.msg
        }
        this.snackbar = true
      } catch (e) {
        console.log(e)
      }
    },

    async formBThird() {
      if (this.preValueList.outcome == "" || this.preValueList.outcome == null) {
        this.errr18 = false
        return false,
          this.snackbarText = "Please Select Outcome.",
          this.snackbar = true
      } if (this.preValueList.outcome == "Approached - Not consented") {
        this.preValueList.not_consented = true;
        if (this.preValueList.not_consented_reason == "" || this.preValueList.not_consented_reason == null) {
          this.errr19 = false
          return false,
            this.snackbarText = "Please Fill the Outcome.",
            this.snackbar = true
        } else {
          this.errr19 = true
        }
      } if (this.preValueList.outcome == "Consented - Not recovered") {
        this.preValueList.not_recovered = true;
        if (this.preValueList.not_recovered_reason == "" || this.preValueList.not_recovered_reason == null) {
          this.errr20 = false
          return false,
            this.snackbarText = "Please Fill the Outcome.",
            this.snackbar = true
        } else {
          this.errr20 = true
        }
      }
      if (this.preValueList.outcome == "Consented - Donor") {
        this.preValueList.consented_donor = true;
        if (this.preValueList.consented_donor_reason == "" || this.preValueList.consented_donor_reason == null) {
          this.errr21 = false
          return false,
            this.snackbarText = "Please Fill the Outcome.",
            this.snackbar = true
        } else[
          this.errr21 = true
        ]
      } else {
        this.errr18 = true
      }



      const data = {
        donor_id: this.$route.params.donor_id,
        userid: userId,
        outcome: this.preValueList.outcome,
        not_consented: this.preValueList.not_consented,
        not_consented_reason: this.preValueList.not_consented_reason,
        not_consented_description: this.preValueList.not_consented_description,
        not_recovered: this.preValueList.not_recovered,
        not_recovered_reason: this.preValueList.not_recovered_reason,
        not_recovered_description: this.preValueList.not_recovered_description,
        consented_donor: this.preValueList.consented_donor,
        consented_donor_reason: this.preValueList.consented_donor_reason
      }


      try {
        const response = await api.post(`donorCase/add`, data, {
          headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        })
        if (response.data.status === true) {
          this.snackbarText = response.data.msg
          this.donorDeatilView()
        } else {
          this.snackbarText = response.data.msg
        }
        this.snackbar = true
      } catch (e) {
        console.log(e)
      }
    },

    async formBFour() {
      if (this.preValueList.date_time == "" || this.preValueList.date_time == "Invalid date") {
        this.preValueList.date_time = null
      }

      const data = {
        donor_id: this.$route.params.donor_id,
        userid: userId,
        person_consulted: this.preValueList.person_consulted,
        date_time: this.preValueList.date_time,
        topic: this.preValueList.topic,
        discussion: this.preValueList.discussion,
      }
      try {
        const response = await api.post(`donorCase/add`, data, {
          headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        })
        if (response.data.status === true) {
          this.snackbarText = response.data.msg
          this.donorDeatilView()
        } else {
          this.snackbarText = response.data.msg
        }
        this.snackbar = true
      } catch (e) {
        console.log(e)
      }
    },

  },

}
</script>
<style >
.diii {
  display: flex;

}
</style>
